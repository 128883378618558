import * as firebase from 'firebase/app';
import 'firebase/analytics';

export const logClassLoaded = ({ classId }) => {
    const analytics = firebase.analytics();
    analytics.logEvent('classLoaded', {
        classId,
    });
};

export const logInstructorLoaded = ({ instructorId }) => {
    const analytics = firebase.analytics();
    analytics.logEvent('instructorLoaded', {
        instructorId,
    });
};

export const logInstructorSelected = ({ instructorId, source }) => {
    const analytics = firebase.analytics();
    analytics.logEvent('instructorSelected', {
        instructorId,
        source
    });
};

export const logInstructorUpcomingClassClicked = () => {
    const analytics = firebase.analytics();
    analytics.logEvent('instructorUpcomingClassClicked', {});
};

export const logImInterestedPressed = ({ classId }) => {
    const analytics = firebase.analytics();
    analytics.logEvent('imInterestedPressed', {
        classId
    });
};

export const logVisitLinkPressed = ({ classId }) => {
    const analytics = firebase.analytics();
    analytics.logEvent('visitLinkPressed', {
        classId
    });
};

export const logClassSelected = ({ classId, danceStyle, difficulty }) => {
    const analytics = firebase.analytics();
    analytics.logEvent('classSelected', {
        classId,
        danceStyle,
        difficulty
    });
}