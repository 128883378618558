import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { Link } from "react-router-dom";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";

import {
    formatEventStartTime,
    getNextOrLastOccurringDateIdx
} from "utils/dates";
import {
    logInstructorUpcomingClassClicked
} from "../../utils/firebaseAnalytics";
import {
    capitalizeArrayOfText,
    getUrlFriendlyEventTitle
} from "../../utils/text";

export default function ClassCard({ data }) {
    const classes = useStyles();
    const normalized = data.difficulty[0].toLowerCase();
    const colorClassname = classes[normalized] ? classes[normalized] : classes.default;
    const urlFriendlyTitle = getUrlFriendlyEventTitle(data.title);
    const nextOrLastOccurrenceIdx = getNextOrLastOccurringDateIdx(data.schedule, new Date());
    const eventDate = data.schedule[nextOrLastOccurrenceIdx];

    return (<Card className={classNames(classes.root, colorClassname)}>
        <CardActionArea
            onClick={() => logInstructorUpcomingClassClicked()}
            to={`/c/${urlFriendlyTitle}-${data.id}`}
            component={Link}
        >
            <div className={classes.details}>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                        {data.title}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" component="p">
                        <strong>{capitalizeArrayOfText(data.dance_styles).join(", ")}</strong>
                    </Typography>
                    <div className={classes.dateDisplay}>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {formatEventStartTime(eventDate.starts_at)}
                        </Typography>
                        {data.schedule.length > 1 && <Typography className={classes.additionalDates} variant="body2">
                            {`+${data.schedule.length - 1}`}
                        </Typography>}
                    </div>
                </CardContent>
            </div>
        </CardActionArea>
        <CardMedia
            className={classes.media}
            image={data.media.coverUrl}
            title={data.title}
        />
    </Card>);
}

ClassCard.propTypes = {
    data: PropTypes.object.isRequired
};

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: 'row',
        borderLeft: "5px solid green"
    },
    dateDisplay: {
        display: "flex",
        flexDirection: 'row',
    },
    additionalDates: {
        color: "#333",
        backgroundColor: "#e9e9e9",
        marginLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingTop: "1px",
        paddingBottom: "1px",
        borderRadius: 8,
    },
    media: {
        width: 180,
    },
    details: {
        // display: 'flex',
        flexDirection: 'column',
    },
    beginner: {
        borderColor: "#ea0"
    },
    intermediate: {
        borderColor: "purple"
    },
    advanced: {
        borderColor: "#111"
    },
    all: {
        borderColor: "green"
    },
    default: {
        borderColor: "white"
    },
}));