import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

import { capitalize } from '../utils/text';
const difficultiesInOrder = ['beginner', 'intermediate', 'advanced'];

class DifficultyPillSummary extends React.Component {
    render() {
        const { difficulties, classes, short, abbrev } = this.props;
        let colorClassname = classes.all;
        let levelsText = abbrev ? "All" : "All levels";

        if (!difficulties.includes('all')) {
            const difficultiesByValue = difficulties.map((d) => difficultiesInOrder.indexOf(d.toLowerCase()));
            const lo = Math.min(...difficultiesByValue);
            const hi = Math.max(...difficultiesByValue);
    
            const loDifficulty = difficultiesInOrder[lo];
            const hiDifficulty = difficultiesInOrder[hi];

            const textLo = abbrev ? loDifficulty.substr(0, 3) + '.' : loDifficulty;
            const textHi = abbrev ? hiDifficulty.substr(0, 3) + '.' : hiDifficulty;

            if (lo === hi) {
                levelsText = capitalize(textLo);
            } else if (short) {
                levelsText = capitalize(textLo) + '+';
            } else {
                levelsText = capitalize(textLo) + ' to ' + capitalize(textHi);
            }

            colorClassname = classes[loDifficulty] ? classes[loDifficulty] : classes.default;
        }

        return (<Typography variant='caption' className={classNames(classes.text, colorClassname)}>
            {levelsText}
        </Typography>);
    }
}

DifficultyPillSummary.propTypes = {
    classes: PropTypes.object.isRequired,
    difficulties: PropTypes.array.isRequired,
    short: PropTypes.bool,
    abbrev: PropTypes.bool
};

const useStyles = (theme) => ({
    text: {
        height: "30px",
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingTop: "3px",
        paddingBottom: "3px",
        borderRadius: 8,
        marginRight: 5,
        justifyContent: "center",
        textAlign: 'center',
        color: "white",
        fontWeight: 500
    },
    beginner: {
        backgroundColor: "#ea0",
    },
    intermediate: {
        backgroundColor: "purple",
    },
    advanced: {
        backgroundColor: "#111",
    },
    all: {
        backgroundColor: "green",
    },
    default: {
        backgroundColor: "white",
    },
});

export default withStyles(useStyles)(DifficultyPillSummary);