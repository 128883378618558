import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

class DifficultyPill extends React.Component {
    formatDifficultyText = (str) => {
        const newStr = (str === 'all') ? 'All levels' : str;
        return newStr.charAt(0).toUpperCase() + newStr.slice(1);
    }

    render() {
        const { formatDifficultyText } = this;
        const { difficulty, classes } = this.props;
        const normalized = difficulty.toLowerCase();
        const colorClassname = classes[normalized] ? classes[normalized] : classes.default;

        return (
            <Typography variant='caption' className={classNames(classes.text, colorClassname)}>
                <Link
                    href={`${process.env.PUBLIC_URL}/download`}
                    className={classes.link}
                >
                    {formatDifficultyText(difficulty)}
                </Link>
            </Typography>
        );
    }
}

DifficultyPill.propTypes = {
    classes: PropTypes.object.isRequired,
    difficulty: PropTypes.string.isRequired
};

const useStyles = (theme) => ({
    text: {
        height: "30px",
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingTop: "3px",
        paddingBottom: "3px",
        borderRadius: 8,
        marginRight: 5,
        justifyContent: "center",
        textAlign: 'center',
        color: "white",
        border: "1px solid white",

    },
    link: {
        color: "white",
        "&:hover,&:focus": {
            color: "white",
            textDecoration: "none"
        }
    },
    beginner: {
        backgroundColor: "#ea0"
    },
    intermediate: {
        backgroundColor: "purple"
    },
    advanced: {
        backgroundColor: "#111"
    },
    all: {
        backgroundColor: "green"
    },
    default: {
        backgroundColor: "white"
    },
});

export default withStyles(useStyles)(DifficultyPill);