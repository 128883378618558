import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import * as firebase from 'firebase/app';
import 'firebase/auth';

import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import InstructorProfilePage from "views/InstructorProfilePage/InstructorProfilePage.js";
import ClassEventPage from "views/ClassEventPage/ClassEventPage.js";
import ClassesListPage from "views/ClassesListPage/ClassesListPage.js";
import InstructorsListPage from "views/InstructorsListPage/InstructorsListPage.js";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderAppLinks from "components/Header/HeaderAppLinks.js";

class App extends React.Component {
    state = {
        error: null,
        loading: true,
        isSignedIn: false
    };

    componentDidMount = () => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    error: null,
                    loading: false,
                    isSignedIn: true
                });

                unsubscribe && unsubscribe();
            }
        });

        firebase.auth().signInAnonymously().catch(error => {
            console.log("Unable to signing in", error);
            this.setState({
                error: "Something didn't go right. Please try refreshing.",
                loading: false,
                isSignedIn: false
            });
        });
    }

    render() {
        const { loading, error } = this.state;
        const { classes, ...rest } = this.props;

        return (<div className={classes.root}>
            <Header
                color="black"
                rightLinks={<HeaderAppLinks />}
                brand="Dance Tonight"
                {...rest}
            />
            <div className={classes.container}>
                    {loading && <div className={classes.progress}>
                        <CircularProgress />
                    </div>}
                    {!loading && error && <div>
                        <Typography variant="h2" component={"div"} gutterBottom>
                            Oops
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {error}
                        </Typography>
                    </div>}
                    {!loading && !error && <Switch>
                        <Route path="/classes" component={ClassesListPage} />
                        <Route path="/instructors" component={InstructorsListPage} />
                        <Route path="/c/:id" render={(props) => (<ClassEventPage {...props} key={`classEvent-${props.match.params.id}`} />)} />
                        <Route path="/p/:id" component={InstructorProfilePage} />
                        <Route path="/:handle([a-zA-Z0-9\._]{4,30})" component={InstructorProfilePage} />
                        <Redirect to="/classes" />
                    </Switch>}
                <Footer />
            </div>
        </div>);
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
};

const useStyles = (theme) => ({
    root: {
        minHeight: "100vh",
    },
    container: {
        margin: "8px auto 0 auto",
        maxWidth: theme.breakpoints.values.md, // 960px
    },
    progress: {
        textAlign: "center",
        paddingTop: "150px",
        paddingBottom: "150px",
    }
});

export default withStyles(useStyles)(App);