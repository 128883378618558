import React from "react";
import PropTypes from 'prop-types';
import Linkify from 'linkifyjs/react';

import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "assets/jss/dancetonight.js";

export default function Linkifier({ children }) {
    const classes = useStyles();
    return (<Linkify
        options={{
            attributes: {
                rel: "noopener noreferrer"
            },
            className: classes.link,
            validate: (href, linkType) => {
                return linkType === "url";
            }
        }}
    >
        {children}
    </Linkify>)
}

Linkifier.propTypes = {
    children: PropTypes.node.isRequired,
};

const useStyles = makeStyles({
    link: {
        color: primaryColor,
        textDecoration: "none",
        backgroundColor: "transparent",
        "&:hover,&:focus": {
            color: "purple",
            textDecoration: "none"
        }
    }
})