import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import {Helmet} from "react-helmet";
import LazyLoad from 'react-lazyload';

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import HomeIcon from '@material-ui/icons/Home';
import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

// core components
import Button from "components/CustomButtons/Button.js";

import { primaryColor } from "assets/jss/dancetonight.js";
import { getProfileById, getProfileByUserHandle } from "utils/firebaseStore";
import { logInstructorLoaded } from "utils/firebaseAnalytics";
import UpcomingClasses from "./UpcomingClasses";

const PROFILE_ID_REGEX = /^(?:.*-)?([a-zA-Z0-9]+)$/;

class InstructorProfilePage extends React.Component {
    state = {
        error: null,
        loading: true,
        data: null
    };

    componentDidMount = () => {
        const profileId = this.getProfileIdFromUrl();
        const userHandle = this.getUserHandleFromUrl();

        if (userHandle) {
            getProfileByUserHandle(userHandle).then((querySnapshot) => {
                let found = false;
                querySnapshot.forEach((doc) => {
                    const instructorData = {
                        id: doc.id,
                        ...doc.data()
                    };
                    
                    this.setState({
                        error: null,
                        loading: false,
                        data: instructorData
                    })

                    logInstructorLoaded({
                        instructorId: instructorData.id
                    });

                    found = true;
                });

                if (!found) {
                    this.setState({
                        error: "We could not find that instructor",
                        loading: false,
                        data: null
                    });
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
                this.setState({
                    error: "Oops, something went wrong. Please try refreshing.",
                    loading: false,
                    data: null
                });
            });
        } else {
            getProfileById(profileId).then((doc) => {
                if (doc.exists) {
                    const instructorData = {
                        id: doc.id,
                        ...doc.data()
                    };
                    
                    this.setState({
                        error: null,
                        loading: false,
                        data: instructorData
                    })

                    this.props.history.replace(`/${instructorData.handle}`);
                    logInstructorLoaded({
                        instructorId: instructorData.id
                    })
                } else {
                    this.setState({
                        error: "We could not find that instructor",
                        loading: false,
                        data: null
                    });
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
                this.setState({
                    error: "Oops, something went wrong. Please try refreshing.",
                    loading: false,
                    data: null
                });
            });
        }
    }

    getUserHandleFromUrl = () => {
        const { match: { params } } = this.props;
        return params.handle;
    }

    getProfileIdFromUrl = () => {
        const { match: { params } } = this.props;
        if (params.id) {
            const result = params.id.match(PROFILE_ID_REGEX);

            if (result && result[1]) {
                return result[1];
            }
        }

        return undefined;
    }

    renderHeaderMeta = (data) => {
        const title = `${data.firstname} ${data.lastname} - ${data.title} - Dance Tonight`;
        let description = "Bio";
        if (data.bio) {
            description = (data.bio.length > 157)
                ? data.bio.substring(0, 157).trim() + '...'
                : data.bio.substring(0, data.bio.length).trim();
        }

        return (<Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta property="og:image" content={data.media.coverUrl} />

            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            {/* <meta property="og:url" content={`https://dancetonightbayarea.com/${data.handle}`} /> */}
        </Helmet>)
    }

    render() {
        const { renderHeaderMeta } = this;
        const { classes } = this.props;
        const { loading, error, data } = this.state;

        return (
            <div>
                {!loading && data && renderHeaderMeta(data)}
                {loading &&
                <div className={classes.loaderBody}>
                    <CircularProgress />
                </div>}
                {!loading && error &&
                <div className={classes.errorContent}>
                    <h2>Oops</h2>
                    <Typography variant="body2" gutterBottom>{error}</Typography>
                </div>}
                {!loading && data &&
                <div>
                    <Card className={classes.mainCard}>
                        {data.media.coverUrl &&
                        <div className={classes.titleWrapper}>
                            <CardMedia
                                className={classes.media}
                                component="img"
                                image={data.media.coverUrl}
                                title={`${data.firstname} ${data.lastname}`}
                            />
                            <div className={classes.mediaOverlay}>
                                <div className={classes.instructorBrief}>
                                    <h2 className={classes.instructorHeader}>{data.firstname} {data.lastname}</h2>
                                    <div>{data.title}</div>
                                    <div>
                                        {data.social.fb && <Button
                                            justIcon
                                            link
                                            className={classNames(classes.margin5, classes.button)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`https://facebook.com/${data.social.fb}`}
                                        >
                                            <FacebookIcon />
                                        </Button>}
                                        {data.social.insta && <Button
                                            justIcon
                                            link
                                            className={classNames(classes.margin5, classes.button)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`https://instagram.com/${data.social.insta}`}
                                        >
                                            <InstagramIcon />
                                        </Button>}
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {!data.media.coverUrl &&
                        <div className={classes.titleWrapperNoCover}>
                            <div className={classes.instructorBrief}>
                                <h2 className={classes.instructorHeader}>{data.firstname} {data.lastname}</h2>
                                <div>{data.title}</div>
                                <div>
                                    {data.social && data.social.fb && <Button
                                        justIcon
                                        link
                                        className={classNames(classes.margin5, classes.button)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`https://facebook.com/${data.social.fb}`}
                                    >
                                        <FacebookIcon />
                                    </Button>}
                                    {data.social && data.social.insta && <Button
                                        justIcon
                                        link
                                        className={classNames(classes.margin5, classes.button)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`https://instagram.com/${data.social.insta}`}
                                    >
                                        <InstagramIcon />
                                    </Button>}
                                </div>
                            </div>
                        </div>}
                        <CardContent>
                            <div>
                                <div className={classes.aboutWrapper}>
                                    <Avatar
                                        alt={`${data.firstname} ${data.lastname}`}
                                        className={classes.avatar}
                                        src={data.media.profileUrl}
                                    />
                                    <h3 className={classes.aboutText}>About {data.firstname} {data.lastname}</h3>
                                </div>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <HomeIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {data.city}
                                        </ListItemText>
                                    </ListItem>
                                    {data.website && <ListItem>
                                        <ListItemIcon>
                                            <LanguageIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Link
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={data.website}
                                                className={classes.link}
                                            >
                                                {data.website}
                                            </Link>
                                        </ListItemText>
                                    </ListItem>}
                                </List>
                            </div>
                            <div>
                                <h3>Dance styles</h3>
                                <div>
                                    {data.dance_styles.map((ds, i) => {
                                        const danceStyle = ds.charAt(0).toUpperCase() + ds.slice(1);
                                        return (<Chip
                                            key={`dancestyle-${i}`}
                                            className={classes.chip}
                                            label={danceStyle}
                                            component="a"
                                            clickable
                                            variant="outlined"
                                            href={`${process.env.PUBLIC_URL}/download`}
                                        />)
                                    })}
                                </div>
                            </div>
                            <div>
                                <h3>Services offered</h3>
                                <div>
                                    {data.services.map((ds, i) => {
                                        const service = ds.charAt(0).toUpperCase() + ds.slice(1);
                                        return (<Chip
                                            key={`service-${i}`}
                                            className={classes.chip}
                                            label={service}
                                            component="a"
                                            clickable
                                            variant="outlined"
                                            href={`${process.env.PUBLIC_URL}/download`}
                                        />);
                                    })}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className={classNames(classes.secondaryCard, classes.transparentCard)}>
                        <CardContent>
                            <div>
                                <h3>Upcoming classes</h3>
                                <LazyLoad>
                                    <UpcomingClasses instructorId={data.id}/>
                                </LazyLoad>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className={classes.secondaryCard}>
                        <CardContent>
                            <div>
                                <h3>Bio</h3>
                                {data.bio.split("\n").map((text, i) => {
                                    return (<Typography variant="body2" gutterBottom key={`biotext-${i}`}>{text}</Typography>)
                                })}
                            </div>
                        </CardContent>
                    </Card>
                </div>}
            </div>
        );
    }
}

InstructorProfilePage.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

const useStyles = (theme) => ({
    mainCard: {
        maxWidth: 600,
        margin: "0 auto 0 auto",
    },
    transparentCard: {
        backgroundColor: "transparent",
        elevation: 0,
        boxShadow: "none"
    },
    secondaryCard: {
        maxWidth: 600,
        margin: "0 auto 0 auto",
    },
    loaderBody: {
        margin: "100px auto 0 auto",
        textAlign: "center",
        paddingTop: "150px",
        paddingBottom: "150px",
    },
    errorContent: {
        maxWidth: 600,
        margin: "100px auto 0 auto",
        paddingTop: "50px",
        paddingBottom: "250px",
    },
    media: {
        height: 600,
    },
    mediaOverlay: {
        position: "absolute",
        background: "linear-gradient(0deg, rgba(0,0,0,0.7343312324929971) 10%, rgba(255,255,255,0) 85%)",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "block"
    },
    titleWrapper: {
        display: "inline-block",
        position: "relative",
        width: "100%"
    },
    titleWrapperNoCover: {
        display: "inline-block",
        position: "relative",
        backgroundColor: "#000000",
        width: "100%",
        height: "300px"
    },
    instructorBrief: {
        height: "100%",
        width: "100%",
        textAlign: "center",
        bottom: 0,
        color: "white",
        justifyContent: "flex-end",
        display: "flex",
        flexDirection: "column",
        paddingBottom: theme.spacing(8),
        fontWeight: "normal"
    },
    instructorHeader: {
        fontWeight: "bold"
    },
    button: {
        color: "#cccccc"
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        float: "left",
        marginRight: theme.spacing(2)
    },
    aboutText: {
        float: "left",
        marginTop: theme.spacing(1)
    },
    aboutWrapper: {
        overflow: "auto"
    },
    chip: {
        marginRight: theme.spacing(1)
    },
    link: {
        color: primaryColor,
        textDecoration: "none",
        backgroundColor: "transparent",
        "&:hover,&:focus": {
            color: "#000000",
            textDecoration: "none"
        }
    }
})

export default withStyles(useStyles)(InstructorProfilePage);