import React from "react";
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import InstructorCard from "./InstructorCard";
import { listInstructors, formatInstructor } from "utils/firebaseStore";

const whitelisted = [
    '8OuzpVdqPACAtcRvpIg2', // Liz
    '95sYJ9RxVdkEppL628MI', // Stefan
    'O9Ud9IIcV0c28ADV0DX3', // Tiffany
    'UtoJaPDXINpDLHkDQph7', // Amanda
    'ar4uWuPRxvaIUWY0iDsU', // Carolyn
    'jqhCCMCddmrZDfQ09eit', // Isabel
    'ng6qjcx9OE5uQXUwJ8by', // Juan
    'rznxZ99Xb55qFixAlDVr', // John
    'v7EMj7TA4sl2LVv4msdN', // Ava
];

class InstructorsListPage extends React.Component {
    state = {
        error: null,
        loading: true,
        data: [],
    };

    componentDidMount = () => {
        this.fetchInstructors();
    }

    fetchInstructors = () => {
        listInstructors().then((querySnapshot) => {
            const data = []
            querySnapshot.forEach((doc) => {
                if (whitelisted.includes(doc.id)) {
                    data.push(formatInstructor(doc.id, doc.data()));
                }
            });

            this.setState({
                error: null,
                loading: false,
                data
            });
        }).catch((error) => {
            console.log("Error getting instructors:", error);
            this.setState({
                error: "Something didn't go right. Please try refreshing.",
                loading: false,
            });
        });
    }

    renderHeaderMeta = () => {
        const title = 'Instructors in the Bay Area - Dance Tonight';
        const description = 'Find dance instructors in the San Francisco Bay Area. Find multiple dance styles such as Bachata, Salsa, Kizomba, and more.';

        return (<Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            {/* <meta property="og:url" content={`https://dancetonightbayarea.com/c/${data.id}`} /> */}
        </Helmet>)
    }

    render() {
        const { renderHeaderMeta } = this;
        const { classes } = this.props;
        const { loading, error, data } = this.state;

        return (<div className={classes.root}>
            {renderHeaderMeta()}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2" component="h1" gutterBottom className={classes.header}>
                        Instructors in the Bay Area
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        {data.map(function(d) {
                            return (<Grid item xs={12} sm={6} md={4} key={`instructor-${d.id}`}>
                                <InstructorCard data={d} />
                            </Grid>);
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" component="div" gutterBottom className={classes.areYouInstructor}>
                        <Link to="/join">
                            Want to join Dance Tonight as an instructor?
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            {loading &&
            <div className={classes.loaderBody}>
                <CircularProgress />
            </div>}
            {!loading && error &&
            <div className={classes.errorContent}>
                <Typography variant="h2" component={"div"} gutterBottom>
                    Oops
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {error}
                </Typography>
            </div>}
        </div>);
    }
}

InstructorsListPage.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

const useStyles = (theme) => ({
    root: {
        margin: "0 auto 0 auto",
        maxWidth: theme.breakpoints.values.md, // 960px
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    loaderBody: {
        textAlign: "center",
        paddingTop: "150px",
        paddingBottom: "150px",
    },
    errorContent: {
        maxWidth: 600,
        paddingTop: "50px",
        paddingBottom: "250px",
    },
    header: {
        paddingTop: theme.spacing(3),
        textAlign: "center"
    },
    areYouInstructor: {
        paddingTop: theme.spacing(3),
        textAlign: "center",
        color: "#444"
    },
    loadMore: {
        padding: theme.spacing(4),
        width: "100%",
        textAlign: "center"
    }
});

export default withStyles(useStyles)(InstructorsListPage);