import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { title, container } from "assets/jss/dancetonight.js";

const productStyle = {
  section: {
    padding: "70px 0",
    ...container
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#777"
  },
  img: {
    maxWidth: "100%",
    maxHeight: "700px",
    height: "auto",
  },
  imgWrapper: {
    textAlign: "center"
  }
};

const useStyles = makeStyles(productStyle);

export default function FindClassesSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div>
        <GridContainer direction="row-reverse" alignItems="center">
          <GridItem xs={12} sm={12} md={6}>
            <h2 className={classes.title}>Find dance classes in the Bay</h2>
            <h5 className={classes.description}>
              Explore one or multiple dance styles.
              From Salsa, Bachata, Kizomba, to a growing selection of styles that keep the fun going.
              There is a class for you whether you’re a beginner or an advanced learner.
            </h5>
            <h4 className={classes.title}>Online classes available</h4>
            <h5 className={classes.description}>Find classes to take from the comfort of your home. During the pandemic, classes are happening online.</h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.imgWrapper}>
            <img
              src={require("assets/img/homescreen-iphone.jpg")}
              alt=""
              className={classes.img}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
