import React from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import downloadSvg from "assets/img/downloadappstore_black.svg";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import { container, title } from "assets/jss/dancetonight.js";

export default function PrivacyPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const description = "Download and start taking classes today. Find Salsa, Bachata, Kizomba, and more styles of dance classes in one app. Available on iOS.";

    return (<div>
        <Helmet>
            <title>Download Dance Tonight</title>
            <meta name="og:title" property="og:title" content="Download Dance Tonight" />
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            {/* <meta property="og:url" content="https://dancetonightbayarea.com/download" /> */}
            <meta name="twitter:card" content="summary" />
        </Helmet>
        <Header
            color="black"
            rightLinks={<HeaderLinks />}
            brand="Dance Tonight"
            absolute
            {...rest}
        />
        <div className={classes.main}>
            <div className={classes.container}>
                <GridContainer alignItems="center" justify="center">
                    <GridItem xs={12} sm={12} md={6} className={classes.imgWrapper}>
                        <img
                            src={require("assets/img/detailsscreen-iphone.jpg")}
                            alt=""
                            className={classes.img}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <h2 className={classes.title} id="download">Download and start taking classes today</h2>
                        <h5 className={classes.description}>
                            Find Salsa, Bachata, Kizomba, and more styles of dance classes in one app. Taught by the best instructors in the Bay Area.
                        </h5>
                        <br/><br/>
                        <a
                            href="https://apps.apple.com/us/app/id1512534760"
                            target="_blank" 
                            rel="noopener noreferrer"
                            alt="Download from App Store"
                        >
                            <img src={downloadSvg} alt="Download" className={classes.img} />
                        </a>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
        <Footer />
    </div>);
}

const landingPageStyle = () => ({
    container: {
        paddingTop: "100px",
        paddingBottom: "60px",
        zIndex: "12",
        color: "#FFFFFF",
        ...container
    },
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3",
        padding: "70px 15px",
        minHeight: "100vh"
    },
    title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        // color: "white"
    },
    description: {
        color: "#777"
    },
    img: {
        maxWidth: "100%",
        maxHeight: "700px",
        height: "auto",
    },
    imgWrapper: {
        textAlign: "center"
    }
});
const useStyles = makeStyles(landingPageStyle);