import React from 'react';
import PropTypes from 'prop-types';

import format from 'date-fns/format';
import isThisYear from 'date-fns/isThisYear';
import isEqual from 'date-fns/isEqual';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";

import {
    formatEventStartTime,
    formatEventDuration,
    isEventHappeningNow,
    getNextOrLastOccurringDateIdx
} from "utils/dates";

const BRIEF_SIZE = 6;

class ClassSchedule extends React.Component {
    now = new Date()
    state = {
        showShowMoreButton: true
    }

    handleShowMore = () => {
        this.setState({
            showShowMoreButton: false
        });
    }

    render() {
        const { handleShowMore } = this;
        const { showShowMoreButton } = this.state;
        const { classes, classData: { schedule, status }, selectedDate } = this.props;
    
        const nextOrLastOccurrenceIdx = getNextOrLastOccurringDateIdx(schedule, this.now)

        // If a date was selected, show that date
        // Otherwise fall back to next or last occurrence
        const foundIdx = selectedDate ? schedule.findIndex((d) => isEqual(d.starts_at, selectedDate)) : -1;
        const whenIdx = (foundIdx === -1) ? nextOrLastOccurrenceIdx : foundIdx;
        const when = schedule[whenIdx];
        
        // Generate a schedule briefing
        const tempIdx = Math.max(schedule.length - BRIEF_SIZE - 1, 0);
        const startingIdx = Math.min(tempIdx, nextOrLastOccurrenceIdx)
        const endIdx = Math.min(schedule.length - 1, startingIdx + BRIEF_SIZE)
        const scheduleBrief = [];

        for (let i = startingIdx; i <= endIdx; i++) {
            if (i !== whenIdx) {
                scheduleBrief.push(schedule[i])
            }
        }

        const scheduleToShow = showShowMoreButton ? scheduleBrief : schedule

        return (<div>
            <h3>When</h3>
            <div>
                {formatEventStartTime(when.starts_at) + ' '}
                <strong>({formatEventDuration(when.starts_at, when.ends_at)})</strong>
                <div>
                    {status === 'confirmed' && isEventHappeningNow(when.starts_at, when.ends_at) &&
                    <Typography variant="subtitle2" color="textSecondary" >
                        Happening now
                    </Typography>}
                </div>
            </div>
            {scheduleToShow.length > 0 && <React.Fragment>
                <h3>More dates</h3>
                <Grid container spacing={1}>
                    {scheduleToShow.map((ds, i) => {
                        const m = ds.starts_at.getMinutes();
                        const hourFormat = (m === 0) ? ' - ha' : ' - h:ma';
                        return (<Grid item xs={12} key={`class-schedule-${i}`}>
                            {"•  "}
                            {format(ds.starts_at, 'E, MMM d')}
                            {!isThisYear(ds.starts_at) ? format(ds.starts_at, ', y') : ''}
                            {format(ds.starts_at, hourFormat)}
                        </Grid>);
                    })}
                </Grid>
            </React.Fragment>}
            {showShowMoreButton && schedule.length > BRIEF_SIZE && <div className={classes.showMoreButton}>
                <Button
                    variant="outlined"
                    onClick={handleShowMore}
                >
                    Show more
                </Button>
            </div>}
            {schedule.length === 0 &&
            <div>
                <Typography variant="body2" gutterBottom>No upcoming classes</Typography>
            </div>}
        </div>);
    }
}

ClassSchedule.propTypes = {
    // schedule: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    classData: PropTypes.object.isRequired,
    selectedDate: PropTypes.object.isRequired,
};

const useStyles = () => ({
    showMoreButton: {
        marginTop: 20,
        textAlign: "center"
    }
});

export default withStyles(useStyles)(ClassSchedule);
