import * as firebase from 'firebase/app';
import 'firebase/firestore';

export const EVENTS_COLLECTION = 'events';
export const INSTRUCTORS_COLLECTION = 'instructors';
export const CLASSES_COLLECTION = 'classes';
export const CLASS_INSTANCES_COLLECTION = 'class_instances';

export function getProfileById(profileId) {
    const db = firebase.firestore();
    var docRef = db.collection(INSTRUCTORS_COLLECTION).doc(profileId);
    return docRef.get();
}

export function getProfileByUserHandle(handle) {
    const db = firebase.firestore();
    var docRef = db.collection(INSTRUCTORS_COLLECTION).where("handle", "==", handle.toLowerCase());
    return docRef.get();
}

export function getClassEventById(eventId) {
    const db = firebase.firestore();
    var docRef = db.collection(CLASSES_COLLECTION).doc(eventId);
    return docRef.get();
}

export function listClassEventsByInstructorId(instructorId) {
    const now = new Date();
    const db = firebase.firestore();
    var docRef = db.collection(CLASSES_COLLECTION)
        .where("instructor_ids", "array-contains", instructorId)
        .where('status', '==', 'published')
        .where('ends_at', '>', now)
        .orderBy('ends_at', 'asc')
        .limit(6);
    return docRef.get();
}

export function listClassEventsByParentId(parentId) {
    const now = new Date();
    const db = firebase.firestore();
    var docRef = db.collection(EVENTS_COLLECTION)
        .where("parent.id", "==", parentId)
        .where('status', '==', 'confirmed')
        .where('ends_at', '>', now)
        .orderBy('ends_at', 'asc')
        .limit(10);
    return docRef.get();
}

export const listUpcomingEvents = (paginationSnapshot) => {
    const db = firebase.firestore();
    const now = new Date();
    let eventsRef = db.collectionGroup(CLASS_INSTANCES_COLLECTION);

    eventsRef = eventsRef
        .where('status', '==', 'confirmed')
        .where('ends_at', '>', now)
        .orderBy('ends_at', 'asc')
        .limit(15);

    if (paginationSnapshot) {
        eventsRef = eventsRef.startAfter(paginationSnapshot);
    }

    return eventsRef.get();
}

export const formatClassInstance = (id, classInstance) => {
    return {
        id,
        ...classInstance,
        created_at: classInstance.created_at.toDate(),
        updated_at: classInstance.updated_at? classInstance.updated_at.toDate() : null,
        starts_at: classInstance.starts_at.toDate(),
        ends_at: classInstance.ends_at.toDate()
    }
}

export const formatDanceEvent = (id, event) => {
    return {
        id,
        ...event,
        created_at: event.created_at.toDate(),
        updated_at: event.updated_at? event.updated_at.toDate() : null,
        starts_at: event.starts_at.toDate(),
        ends_at: event.ends_at.toDate(),
        schedule: event.schedule.map(s => ({
            starts_at: s.starts_at.toDate(),
            ends_at: s.ends_at.toDate()
        }))
    }
}

export const listInstructors = () => {
    const db = firebase.firestore();
    let ref = db.collection(INSTRUCTORS_COLLECTION);

    return ref
        .limit(20)
        .get();
}

export const formatInstructor = (id, data) => {
    return {
        id,
        ...data
    };
}