import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import FindClassesSection from "./FindClassesSection.js";
import ClassDetailsSection from "./ClassDetailsSection.js";
import InstructorsSection from "./InstructorsSection.js";
import DownloadSection from "./DownloadSection.js";
import InstructorContactSection from "./InstructorContactSection.js";
import { container, title } from "assets/jss/dancetonight.js";

const useStyles = makeStyles((theme) => ({
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        ...container
    },
    container2: {
        zIndex: "12",
        color: "#FFFFFF",
        paddingRight: "15px",
        paddingLeft: "15px",
    },
    title: {
        ...title,
        display: "inline-block",
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        color: "#FFFFFF",
        textDecoration: "none"
    },
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3"
    },
    button: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        backgroundColor: '#ddd',
        color: "black",
        "&:hover,&:focus": {
            backgroundColor: "#ea0",
            color: "white"
        }
    }
}));

export default function LandingPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    return (
        <div>
            <Header
                color="transparent"
                brand="Dance Tonight"
                rightLinks={<HeaderLinks useDownloadElementId />}
                absolute
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax filter image={require("assets/img/landing-bg.jpg")}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <h1 className={classes.title}>Discover the culture of dance</h1>
                            <h4>
                                Finding a dance class has never been this easy. Dance Tonight helps you find
                                dance classes taught by the best instructors in the Bay Area.
                            </h4>
                            <div>
                                <Button
                                    className={classes.button}
                                    to="/classes"
                                    component={Link}
                                >
                                    Explore classes
                                </Button>
                                {/* <Button
                                    className={classes.button}
                                    to="/instructors"
                                    component={Link}
                                >
                                    Find instructors
                                </Button> */}
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classes.main}>
                <div className={classes.container2}>
                    <FindClassesSection />
                    <ClassDetailsSection />
                    <InstructorsSection />
                    <DownloadSection />
                    <InstructorContactSection />
                </div>
            </div>
            <Footer />
        </div>
    );
}
