/*eslint-disable*/
import React from "react";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";

import { defaultFont } from "assets/jss/dancetonight.js";
import tooltip from "assets/jss/dancetonight/tooltipsStyle.js";

export default function HeaderLinks(props) {
    const dowloadLinkLocation = props.useDownloadElementId ? '#download' : 'download';
    const classes = useStyles();
    return (<List className={classes.list}>
        <ListItem className={classes.listItem}>
            <NavLink
                to="/classes"
                className={classes.navLink}
                activeClassName={classes.navLinkSelected}
            >
                Classes
            </NavLink>
        </ListItem>
        <ListItem className={classes.listItem}>
            <NavLink
                to="/instructors"
                className={classes.navLink}
                activeClassName={classes.navLinkSelected}
            >
                Instructors
            </NavLink>
        </ListItem>
    </List>);
}

const useStyles = makeStyles(theme => ({
    list: {
      ...defaultFont,
      fontSize: "14px",
      margin: 0,
      paddingLeft: "0",
      listStyle: "none",
      paddingTop: "0",
      paddingBottom: "0",
      color: "inherit"
    },
    listItem: {
      float: "left",
      color: "inherit",
      position: "relative",
      display: "block",
      width: "auto",
      margin: "0",
      padding: "0",
    },
    navLink: {
      color: "#bbb",
      position: "relative",
      padding: "0.9375rem",
      fontWeight: "400",
      fontSize: "12px",
      textTransform: "uppercase",
      borderRadius: "3px",
      lineHeight: "20px",
      textDecoration: "none",
      margin: "0px",
      display: "inline-flex",
      "&:hover,&:focus": {
        color: "white",
        background: "rgba(200, 200, 200, 0.2)"
      },
    },
    navLinkSelected: {
        fontWeight: 800,
        color: "white"
    }
}));