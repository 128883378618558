import React from "react";
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import classNames from "classnames";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

// @material-ui/icons
import SpeedIcon from '@material-ui/icons/Speed';
import RoomIcon from '@material-ui/icons/Room';
import VideocamIcon from '@material-ui/icons/Videocam';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import LaunchIcon from '@material-ui/icons/Launch';

// core components
import Linkifier from "components/Linkifier";
import ClassInstructors from "./ClassInstructors";
import ClassDetails from "./ClassDetails";
import ClassOptions from "./ClassOptions";
import ClassSchedule from "./ClassSchedule";

import { primaryColor } from "assets/jss/dancetonight.js";
import { getClassEventById, formatDanceEvent } from "utils/firebaseStore";
import DifficultyPill from "../../components/DifficultyPill";
import { logClassLoaded, logVisitLinkPressed } from "utils/firebaseAnalytics";

const CLASSEVENT_ID_DEPRECATED_REGEX = /^(?:.*-)?([a-zA-Z0-9_]+_[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}-[0-9]{2}:[0-9]{2})$/;
const CLASSEVENT_ID_REGEX = /^(?:.*-)?([a-zA-Z0-9]+)$/;

const capitalizeArrayOfText = (arrayOfText) => {
    return arrayOfText.map((text) => text.charAt(0).toUpperCase() + text.substring(1));
}

const getGoogleMapsLink = (query) => {
    const escapedQuery = encodeURIComponent(query);
    return `https://www.google.com/maps/search/?api=1&query=${escapedQuery}`
}

const getCostSummary = (classOptions) => {
    let costCaption = 'Free';
    if (classOptions.length === 1) {
        const costValues = classOptions.map(option => option.value);
        costCaption = (costValues[0] === 0) ? 'Free' : `$${costValues[0]}`;
    } else if (classOptions.length > 1) {
        const costValues = classOptions.map(option => option.value);
        const loCost = Math.min(...costValues);
        const hiCost = Math.max(...costValues);
        costCaption = (loCost === hiCost) ? `$${loCost}` : `$${loCost}+`;
    }

    return costCaption;
}

class ClassEventPage extends React.Component {
    state = {
        error: null,
        loading: true,
        data: null,
        dateSelected: null,
        isClassEventActionBarSticky: false,
        previousStickyOffset: 0
    };

    constructor(props) {
        super(props)
        this.classEventActionBarRef = React.createRef();
        this.now = new Date()
    }

    componentDidMount = () => {
        const eventId = this.getClassEventIdFromUrl();
        const dateSelected = this.getDateSelectionFromUrlQuery();

        getClassEventById(eventId).then((doc) => {
            if (doc.exists) {
                const classData = formatDanceEvent(doc.id, doc.data())
                this.setState({
                    error: null,
                    loading: false,
                    data: classData,
                    dateSelected: new Date(dateSelected)
                });

                logClassLoaded({
                    classId: classData.id
                })
            } else {
                this.setState({
                    error: "We could not find that event",
                    loading: false,
                    data: null
                });
            }
        }).catch(error => {
            console.log("Error getting document:", error);
            this.setState({
                error: "Something didn't go right. Please try refreshing.",
                loading: false,
                data: null
            });
        });

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (!this.classEventActionBarRef || !this.classEventActionBarRef.current) {
            return;
        }

        const { isClassEventActionBarSticky, previousStickyOffset } = this.state;
        const windowYOffset = window.pageYOffset;
        const headerYOffset = this.classEventActionBarRef.current.offsetTop;

        if (!isClassEventActionBarSticky) {
            if (windowYOffset > headerYOffset) {
                this.setState({
                    isClassEventActionBarSticky: true,
                    previousStickyOffset: headerYOffset
                });
            }
        } else if (windowYOffset <= previousStickyOffset) {
            this.setState({
                isClassEventActionBarSticky: false,
                previousStickyOffset: 0
            });
        }
    }

    getClassEventIdFromUrl = () => {
        const { match: { params } } = this.props;
        const result = params.id.match(CLASSEVENT_ID_REGEX);

        if (result && result[1]) {
            return result[1];
        } else {
            // No result found, try deprecated ID type
            const resultDeprecated = params.id.match(CLASSEVENT_ID_DEPRECATED_REGEX);
            if (resultDeprecated && resultDeprecated[1]) {
                return resultDeprecated[1];
            }
        }

        return undefined;
    }

    getDateSelectionFromUrlQuery = () => {
        const query = new URLSearchParams(this.props.location.search)
        return query.get("d")
    }

    renderHeaderMeta = (data) => {
        const description = (data.description.length > 157)
             ? data.description.substring(0, 157).trim() + '...'
             : data.description.substring(0, data.description.length).trim();
        return (<Helmet>
            <title>{data.title} - Dance Tonight</title>
            <meta property="og:title" content={data.title} />
            <meta property="og:image" content={data.media.coverUrl} />

            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            {/* <meta property="og:url" content={`https://dancetonightbayarea.com/c/${data.id}`} /> */}
        </Helmet>)
    }

    handleActionButtonClick = () => {
        const { data } = this.state;
        logVisitLinkPressed({
            classId: data.id
        });
    }

    render() {
        const { renderHeaderMeta, handleActionButtonClick } = this;
        const { classes } = this.props;
        const {
            loading,
            error,
            data,
            isClassEventActionBarSticky,
            dateSelected
        } = this.state;
        let actionButtonText = "Visit link";

        return (<div>
            {!loading && data && renderHeaderMeta(data)}
            {loading &&
            <div className={classNames(classes.root, classes.loaderBody)}>
                <CircularProgress />
            </div>}
            {!loading && error &&
            <div className={classNames(classes.root, classes.errorContent)}>
                <Typography variant="h2" component={"div"} gutterBottom>
                    Oops
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {error}
                </Typography>
            </div>}
            {!loading && data &&
            <div className={classes.root}>
                <Card>
                    <div className={classes.coverWrapper}>
                        <CardMedia
                            className={classes.media}
                            component="img"
                            image={data.media.coverUrl}
                            title={`${data.firstname} ${data.lastname}`}
                        />
                        <div className={classes.mediaOverlay}>
                            <div className={classes.titleWrapper}>
                                <h2 className={classes.eventTitle}>{data.title}</h2>
                            </div>
                        </div>
                    </div>
                    <div
                        className={classNames(classes.classEventActionBarWrapper, isClassEventActionBarSticky ? classes.sticky: "")}
                        ref={this.classEventActionBarRef}
                    >
                        <div className={classes.classEventActionBar}>
                            <Grid container>
                                <Hidden xsDown>
                                    <Grid item sm={6}>
                                        <Typography variant="h6" component={"div"} noWrap>
                                            {isClassEventActionBarSticky && data.title}
                                        </Typography>
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12} sm={6} className={classes.classEventActionBarRight}>
                                    <div className={classes.priceSummary}>
                                        <Typography variant="h6">
                                            <strong>{getCostSummary(data.class_options)}</strong>
                                        </Typography>
                                    </div>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick={handleActionButtonClick}
                                        href={data.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        endIcon={<LaunchIcon />}
                                    >
                                        {actionButtonText}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className={classes.classMainDetailsContent}></div>
                </Card>
                <div className={classes.gridWrapper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={7}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <List>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <SpeedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Typography variant="body1">
                                                            <strong>{capitalizeArrayOfText(data.dance_styles).join(", ")}</strong>
                                                        </Typography>
                                                        <div>
                                                            {data.difficulty.map((d, i) => {
                                                                return (<DifficultyPill difficulty={d} key={`difficulty-${i}`} />);
                                                            })}
                                                        </div>
                                                    </ListItemText>
                                                </ListItem>
                                                {data.is_virtual_event &&
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <VideocamIcon />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Live (via {data.location_virtual.platform}) from {data.location_virtual.city}
                                                        {data.location_virtual.link &&
                                                        <div>
                                                            <Typography variant="caption" color="textSecondary">
                                                                <Link
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href={data.location_virtual.link}
                                                                    className={classes.link}
                                                                >
                                                                    {data.location_virtual.link}
                                                                </Link>
                                                            </Typography>
                                                        </div>}
                                                    </ListItemText>
                                                </ListItem>}
                                                {!data.is_virtual_event &&
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <RoomIcon />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        {data.location.name}
                                                        <div>
                                                            <Typography variant="caption" color="textSecondary">
                                                                <Link
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href={getGoogleMapsLink(`${data.location.name}, ${data.location.address}`)}
                                                                    className={classes.link}
                                                                >
                                                                    {data.location.address}
                                                                </Link>
                                                            </Typography>
                                                        </div>
                                                    </ListItemText>
                                                </ListItem>}
                                                {!!data.website &&
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <InsertLinkIcon />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Link
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href={data.website}
                                                            className={classes.link}
                                                        >
                                                            Event link
                                                        </Link>
                                                    </ListItemText>
                                                </ListItem>}
                                            </List>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <h3>Instructors</h3>
                                            <ClassInstructors instructors={data.instructors} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <h3>More details</h3>
                                            <ClassDetails details={data.details} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <h3>Description</h3>
                                            <div>
                                                {data.description.split("\n").map((text, i) => {
                                                    return (<Typography variant="body2" gutterBottom key={`description-${i}`}>
                                                        {!text && <br />}
                                                        {!!text && <Linkifier>{text}</Linkifier>}
                                                    </Typography>);
                                                })}
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <ClassSchedule classData={data} selectedDate={dateSelected} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <div id="class_options">
                                                <h3>Price details</h3>
                                                <ClassOptions options={data.class_options} />
                                            </div>
                                        </CardContent>
                                        <CardActions className={classes.paymentWarning} disableSpacing>
                                            <Typography variant="overline">
                                                Dance Tonight does not accept payments
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                Book directly using the event link or follow the description
                                            </Typography>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>}
        </div>);
    }
}

ClassEventPage.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

const useStyles = (theme) => ({
    root: {
        maxWidth: theme.breakpoints.values.md, // 960px
    },
    gridWrapper: {
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1)
        },
        paddingTop: theme.spacing(2)
    },
    transparentCard: {
        backgroundColor: "transparent",
        elevation: 0,
        boxShadow: "none"
    },
    paymentWarning: {
        background: "#eee",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingLeft: theme.spacing(2)
    },
    loaderBody: {
        textAlign: "center",
        paddingTop: "150px",
        paddingBottom: "150px",
    },
    errorContent: {
        maxWidth: 600,
        paddingTop: "50px",
        paddingBottom: "250px",
    },
    media: {
        height: 420,
    },
    mediaOverlay: {
        position: "absolute",
        background: "linear-gradient(0deg, rgba(0,0,0,0.7343312324929971) 10%, rgba(255,255,255,0) 85%)",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "block"
    },
    coverWrapper: {
        position: "relative"
    },
    titleWrapper: {
        height: "100%",
        width: "100%",
        bottom: 0,
        color: "white",
        justifyContent: "flex-end",
        display: "flex",
        flexDirection: "column",
        paddingLeft: theme.spacing(2),
        fontWeight: "normal"
    },
    eventTitle: {
        fontWeight: "bold"
    },
    link: {
        color: primaryColor,
        textDecoration: "none",
        backgroundColor: "transparent",
        "&:hover,&:focus": {
            color: "purple",
            textDecoration: "none"
        }
    },
    priceSummary: {
        paddingRight: theme.spacing(2)
    },
    classEventActionBarWrapper: {
        padding: theme.spacing(2),
        margin: 0,
        boxShadow: "0 1px 5px 0px gray",
        backgroundColor: "white",
    },
    sticky: {
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        zIndex: theme.zIndex.appBar,
        "& + $classMainDetailsContent": {
            marginTop: 68
        }
    },
    classMainDetailsContent:{
        margin: 0
    },
    classEventActionBar: {
        maxWidth: theme.breakpoints.values.md, // 960px
        margin: "0 auto 0 auto",
    },
    classEventActionBarRight: {
        display: "flex",
        justifyContent: "flex-end"
    },
    actionButton: {
        backgroundColor: "black",
        "&:hover,&:focus": {
            backgroundColor: "#ea0",
            color: "black",
            textDecoration: "none"
        }
    },
    '@keyframes blinker': {
        "50%": {backgroundColor: "yellow"},
    },
    blinkerAnimation: {
        animation: '$blinker 1.5s linear'
    },
})

export default withStyles(useStyles)(ClassEventPage);