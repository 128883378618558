import React from "react";
import ReactDOM from "react-dom";
import {Helmet} from "react-helmet";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import * as firebase from 'firebase/app';
import { firebaseConfig } from "./config";

import "assets/scss/dancetonight.scss?v=1.8.0";

// pages for this product
import LandingPage from "views/StaticPages/LandingPage/LandingPage.js";
import DownloadPage from "views/StaticPages/DownloadPage/DownloadPage.js";
import JoinPage from "views/StaticPages/JoinPage/JoinPage.js";
import PrivacyPage from "views/StaticPages/PrivacyPage/PrivacyPage.js";

import App from "./App";

firebase.initializeApp(firebaseConfig);
var hist = createBrowserHistory();

ReactDOM.render(
    <Router history={hist}>
        {/* This Helmet block is here temporarily
            meta tags get removed since we are setting data-react-helmet="true" in index.js
            Should be fixed with SSR
         */}
        <Helmet>
            <meta name="description" content="Salsa, Bachata, Kizomba, and more. Dance Tonight helps you find dance classes taught by the best instructors in the Bay Area." />
            <meta property="og:title" content="Find dance classes in the Bay Area - Dance Tonight" />
            <meta property="og:description" content="Salsa, Bachata, Kizomba, and more. Dance Tonight helps you find dance classes taught by the best instructors in the Bay Area." />
            <meta property="og:image" content="https://dancetonightbayarea.com/landscapebg.png" />
            {/* <meta property="og:url" content="https://dancetonightbayarea.com/" /> */}
            <meta property="og:type" content="website" />
            <meta property="fb:app_id" content="325404098871961" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Switch>
            <Route path="/download" component={DownloadPage} />
            <Route path="/privacy" component={PrivacyPage} />
            <Route path="/join" component={JoinPage} />
            <Route path="/" exact component={LandingPage} />
            <Route path="/*" component={App} />
        </Switch>
    </Router>,
    document.getElementById("root")
);
