import React from 'react';
import PropTypes from 'prop-types';
import { format, getMinutes, isThisYear, isToday, isTomorrow } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import ClassCardSchedule from "components/ClassCard/ClassCardSchedule";
import {
    getPriceSummaryFromPriceOptions
} from "../../utils/text";

export default function ClassesListRow({ data }) {
    const classes = useStyles();
    const dateStr = format(data.starts_at, isThisYear(data.starts_at) ? 'd MMM' : 'd MMM y');
    const dayOfWeek = format(data.starts_at, isThisYear(data.starts_at) ? 'eee' : 'eee');
    const relativeDay = isToday(data.starts_at) ? 'Today' : isTomorrow(data.starts_at) ? 'Tomorrow' : null;
    const timeStartStr = format(data.starts_at, getMinutes(data.starts_at) === 0 ? 'h' : 'h:m');
    let startPeriod = format(data.starts_at, 'a').toLowerCase();
    const timeStr = `${timeStartStr}${startPeriod}`;

    return (<Grid container>
        <Hidden smDown> {/* md grid */}
            <Grid item xs={12} sm={2} md={2}>
                <div className={classes.dateContainer}>
                    <div className={classes.scheduleDate}>
                        {relativeDay ? relativeDay : dateStr + ' · ' + dayOfWeek}
                    </div>
                    <div>
                        {timeStr}
                    </div>
                </div>
            </Grid>
        </Hidden>
        <Hidden xsDown mdUp> {/* sm grid */}
            <Grid item xs={12} sm={2} md={2}>
                <div className={classes.dateContainer}>
                    <div className={classes.scheduleDate}>
                        {relativeDay ? relativeDay : dateStr}
                    </div>
                    <div className={classes.scheduleDate}>
                        {dayOfWeek}
                    </div>
                    <div>
                        {timeStr}
                    </div>
                </div>
            </Grid>
        </Hidden>
        <Hidden smUp> {/* xs grid */}
            <Grid item xs={9}>
                <div className={classes.dateContainer}>
                    <span className={classes.scheduleDate}>
                        {relativeDay ? relativeDay : dateStr + ' · ' + dayOfWeek} {' · '}
                    </span>
                    <span>
                        {timeStr}
                    </span>
                </div>
            </Grid>
            <Grid item xs={3}>
                <div className={classes.priceContainer}>
                    <div className={classes.priceSummary}>
                        {getPriceSummaryFromPriceOptions(data.class_options)}
                    </div>
                </div>
            </Grid>
        </Hidden>
        <Grid item xs={12} sm={10}>
            <ClassCardSchedule data={data} />
        </Grid>
    </Grid>);
}

ClassesListRow.propTypes = {
    data: PropTypes.object.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: "transparent"
    },
    dateContainer: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            textAlign: "left",
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: "right",
        },
    },
    scheduleDate: {
        fontWeight: "400",
        textTransform: "uppercase",
    },
    priceContainer: {
        paddingTop: theme.spacing(1),
        textAlign: "right",
        paddingRight: theme.spacing(1),
        fontWeight: "400",
    },
    beginnerLevel: {
        color: "#ea0",
    },
    intermediateLevel: {
        color: "purple",
    },
    advancedLevel: {
        color: "black",
    },
    allLevel: {
        color: "green",
    },
}));