/*eslint-disable*/
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import CloudDownload from "@material-ui/icons/CloudDownload";

import Button from "components/CustomButtons/Button.js";
import { defaultFont } from "assets/jss/dancetonight.js";

export default function HeaderLinks(props) {
    const dowloadLinkLocation = props.useDownloadElementId ? '#download' : 'download';
    const classes = useStyles();
    return (
        <List className={classes.list}>
            <ListItem className={classes.listItem}>
                <Button
                  href={`${process.env.PUBLIC_URL}/${dowloadLinkLocation}`}
                  color="transparent"
                  className={classes.navLink}
                >
                    <CloudDownload className={classes.icons} /> Download App
                </Button>
            </ListItem>
        </List>
    );
}

const useStyles = makeStyles(theme => ({
    list: {
        ...defaultFont,
        fontSize: "14px",
        margin: 0,
        paddingLeft: "0",
        listStyle: "none",
        paddingTop: "0",
        paddingBottom: "0",
        color: "inherit"
    },
    listItem: {
        float: "left",
        color: "inherit",
        position: "relative",
        display: "block",
        width: "auto",
        margin: "0",
        padding: "0",
    },
    navLink: {
        color: "inherit",
        position: "relative",
        padding: "0.9375rem",
        fontWeight: "400",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        lineHeight: "20px",
        textDecoration: "none",
        margin: "0px",
        display: "inline-flex",
        "&:hover,&:focus": {
          color: "inherit",
          background: "rgba(200, 200, 200, 0.2)"
        },
        [theme.breakpoints.down("sm")]: {
          width: "calc(100% - 30px)",
          marginLeft: "15px",
          marginBottom: "8px",
          marginTop: "8px",
          textAlign: "left",
          "& > span:first-child": {
            justifyContent: "flex-start"
          }
        }
    },
    icons: {
        width: "20px",
        height: "20px",
        marginRight: "3px"
    },
}));