/*eslint-disable*/
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

export default function Footer(props) {
    const classes = useStyles();
    return (<footer className={classes.footer}>
        <Grid container justify="space-between">
            <Grid item>
                <List className={classes.list}>
                    <ListItem className={classes.inlineBlock}>
                        <a href={`${process.env.PUBLIC_URL}/`}>
                            <Typography variant="body2" className={classes.a}>&copy; Dance Tonight</Typography>
                        </a>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                        <a href={`${process.env.PUBLIC_URL}/privacy`}>
                            <Typography variant="body2" className={classes.a}>Privacy</Typography>
                        </a>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                        <a href={`${process.env.PUBLIC_URL}/download`}>
                            <Typography variant="body2" className={classes.a}>Download</Typography>
                        </a>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                        <a href={`${process.env.PUBLIC_URL}/join`}>
                            <Typography variant="body2" className={classes.a}>Join as an instructor</Typography>
                        </a>
                    </ListItem>
                </List>
            </Grid>
            <Grid item>
                <List className={classes.list}>
                    <ListItem className={classes.inlineBlock}>
                        <Button
                            className={classes.a}
                            size="small"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://facebook.com/dancetonightbayarea`}
                        >
                            <FacebookIcon />
                        </Button>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                        <Button
                            className={classes.a}
                            size="small"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://instagram.com/dancetonightbayarea`}
                        >
                            <InstagramIcon />
                        </Button>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    </footer>);
}

const useStyles = makeStyles((theme) => ({
    footer: {
        borderTop: "solid 1px #ccc",
        marginTop: theme.spacing(4),
        padding: "0.9375rem 8px",
        textAlign: "center",
        display: "flex",
        zIndex: "2",
        position: "relative",
    },
    a: {
        padding: "0.9375rem",
        color: "#444",
        textDecoration: "none",
        backgroundColor: "transparent",
        "&:hover,&:focus": {
            color: "black",
            backgroundColor: "transparent",
        }
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        margin: "0px",
        width: "auto"
    },
}));