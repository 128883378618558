import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import RoomIcon from '@material-ui/icons/Room';

import DifficultyPillSummary from "../../components/DifficultyPillSummary";
import {
    capitalizeArrayOfText,
    getPriceSummaryFromPriceOptions,
    getUrlFriendlyEventTitle
} from "../../utils/text";
import { logClassSelected } from "../../utils/firebaseAnalytics";

export default function ClassCardSchedule({ data }) {
    const classes = useStyles();
    const urlFriendlyTitle = getUrlFriendlyEventTitle(data.title);

    return (
        <Card elevation={0} className={classes.root}>
            <CardActionArea
                onClick={() => logClassSelected({
                    classId: data.id,
                    danceStyle: data.dance_styles[0],
                    difficulty: data.difficulty[0]
                })}
                to={`/c/${urlFriendlyTitle}-${data.class_id}?d=${data.id}`}
                component={Link}
            >
                <div className={classes.cardWrapper}>
                    <CardMedia
                        className={classes.cover}
                        image={data.media.coverUrl}
                        title={data.title}
                        alt={data.title}
                    />
                    <div className={classes.details}>
                        <CardContent className={classes.cardContent}>
                            <div>
                                <Typography variant="h6" component="h2">
                                    {data.title}
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary" component="div">
                                    {capitalizeArrayOfText(data.dance_styles).join(" · ")}
                                </Typography>
                            </div>
                            <Hidden xsDown>
                                <div>
                                    <Typography color="textSecondary" variant="subtitle2" className={classes.priceSummary}>
                                        {getPriceSummaryFromPriceOptions(data.class_options)}
                                    </Typography>
                                </div>
                            </Hidden>
                        </CardContent>
                        <div className={classes.bottomCard}>
                            <Grid container>
                                <Grid item xs className={classes.bottomCardLeft}>
                                    <RoomIcon fontSize="small" className={classes.icon}/>{" "}
                                    <Typography
                                        variant="subtitle2"
                                        component="div"
                                        color="textSecondary"
                                        className={classes.location}
                                        
                                    >
                                        {data.is_virtual_event && "Online"}
                                        {!data.is_virtual_event && data.location.name + " - " + data.location.city}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Hidden smUp>
                                        <DifficultyPillSummary difficulties={data.difficulty} short abbrev />
                                    </Hidden>
                                    <Hidden xsDown>
                                        <DifficultyPillSummary difficulties={data.difficulty} short />
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </CardActionArea>
        </Card>
    );
}

ClassCardSchedule.propTypes = {
    data: PropTypes.object.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: 'row',
    },
    cardWrapper: {
        display: 'flex',
    },
    cardContent: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1)
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%"
    },
    cover: {
        width: 120,
    },
    bottomCard: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    bottomCardLeft: {
        display: 'flex',
        flexDirection: "row",
    },
    icon: {
        marginRight: 3
    },
    location: {
        fontWeight: 400,
        marginRight: theme.spacing(1)
    },
    priceSummary: {
        textAlign: 'right',
        paddingLeft: theme.spacing(1),
        paddingTop: theme.spacing(1)
    }
}));