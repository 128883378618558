import React from "react";
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';

export default function ClassOptions({ options }) {
    return (<List>
        {options.map((option, i) => {
            return (<ListItem key={`classoption-${i}`}>
                <ListItemText>
                    <Typography variant="body1">
                        {option.name}
                    </Typography>
                    <Typography variant="caption" gutterBottom color="textSecondary">
                        {option.description}
                    </Typography>
                </ListItemText>
                <ListItemSecondaryAction>
                    <Typography variant="body1">
                        {"$"}{option.value}
                    </Typography>
                </ListItemSecondaryAction>
            </ListItem>)
        })}
    </List>);
}

ClassOptions.propTypes = {
    options: PropTypes.array.isRequired,
};
