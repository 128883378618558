import React from "react";
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ChildCareIcon from '@material-ui/icons/ChildCare';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PeopleIcon from '@material-ui/icons/People';

import BowtieIcon from "components/BowtieIcon";

export default function ClassDetails({ details }) {
    return (<List dense>
        {details.kids_okay === 'yes' &&
            <ListItem>
                <ListItemIcon>
                    <ChildCareIcon />
                </ListItemIcon>
                <ListItemText>
                    Okay to bring kids
                </ListItemText>
            </ListItem>
        }
        {details.lgbtq_friendly === 'yes' &&
            <ListItem>
                <ListItemIcon>
                    <FavoriteIcon />
                </ListItemIcon>
                <ListItemText>LGBTQ+ friendly</ListItemText>
            </ListItem>
        }
        {details.parner_required && 
            <ListItem>
                <ListItemIcon>
                    <PersonAddIcon />
                </ListItemIcon>
                <ListItemText>
                    {details.parner_required === 'no' ? 'No partner required' : 'Parner required'}
                </ListItemText>
            </ListItem>
        }
        {details.drop_in === 'yes' && 
            <ListItem>
                <ListItemIcon>
                    <CalendarTodayIcon />
                </ListItemIcon>
                <ListItemText>Drop-in any time</ListItemText>
            </ListItem>
        }
        {details.pay_in_advance === 'yes' &&
            <ListItem>
                <ListItemIcon>
                    <CalendarTodayIcon />
                </ListItemIcon>
                <ListItemText>Reserve in advance</ListItemText>
            </ListItem>
        }
        {details.attendee_limit &&
            <ListItem>
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText>Limited to {details.attendee_limit} attendees</ListItemText>
            </ListItem>
        }
        {details.part_of_series && 
            <ListItem>
                <ListItemIcon>
                    <PlayCircleFilledIcon />
                </ListItemIcon>
                <ListItemText>Part of {details.part_of_series} week series</ListItemText>
            </ListItem>
        }
        {details.dress_code && 
            <ListItem>
                <ListItemIcon>
                    <BowtieIcon />
                </ListItemIcon>
                <ListItemText>
                    {details.dress_code.charAt(0).toUpperCase() + details.dress_code.substring(1)} dress code
                </ListItemText>
            </ListItem>
        }
    </List>);
}

ClassDetails.propTypes = {
    details: PropTypes.object.isRequired,
};