import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { title, primaryColor, container } from "assets/jss/dancetonight.js";

const productStyle = {
  section: {
    padding: "220px 0 220px",
    ...container
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  title2: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    color: "white"
  },
  description: {
    color: "#777"
  },
  img: {
    maxWidth: "100%",
    maxHeight: "700px",
    height: "auto",
  },
  contactWrapper: {
    borderRadius: 8,
    backgroundColor: "black",
    paddingBottom: "2rem",
    paddingLeft: "2rem",
  },
  contactTextWrapper: {
    paddingRight: "3rem",
    marginBottom: "2rem",
  },
  link: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent",
    "&:hover,&:focus": {
        color: "purple",
        textDecoration: "none"
    }
},
};

const useStyles = makeStyles(productStyle);

export default function InstructorContactSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={12} md={6} className={classes.contactTextWrapper}>
            <h2 className={classes.title}>Are you an instructor?</h2>
            <h5 className={classes.description}>
              Expand your audience. Find new students.
              Dance Tonight is open to a limited number of instructors and classes in the Bay Area.
              Contact us to find out how to get your classes in our app.
            </h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.contactWrapper}>
            <h3 className={classes.title2}>Instructors</h3>
            <h5 className={classes.description}>
              <a href="mailto:join@dancetonightbayarea.com" alt="" className={classes.link}>
                join@dancetonightbayarea.com
              </a>
            </h5>
            <h3 className={classes.title2}>Other inquiries</h3>
            <h5 className={classes.description}>
              <a href="mailto:contact@dancetonightbayarea.com" alt="" className={classes.link}>
                contact@dancetonightbayarea.com
              </a>
            </h5>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
