import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";

import { listClassEventsByInstructorId, formatDanceEvent } from "utils/firebaseStore";
import ClassCard from "../../components/ClassCard/ClassCard";

class UpcomingClasses extends React.Component {
    state = {
        loading: true,
        error: false,
        data: [],
        showShowMoreButton: true
    }
    componentDidMount = () => {
        const { instructorId } = this.props;

        listClassEventsByInstructorId(instructorId).then((querySnapshot) => {
            const classesData = [];
            querySnapshot.forEach((doc) => {
                classesData.push(formatDanceEvent(doc.id, doc.data()));
            });

            this.setState({
                error: null,
                loading: false,
                data: classesData
            });
        }).catch((error) => {
            console.log("Error getting document:", error);
            this.setState({
                error: "Unable to load upcoming classes",
                loading: false,
                data: []
            });
        });
    }

    handleShowMore = () => {
        this.setState({
            showShowMoreButton: false
        });
    }

    render() {
        const { handleShowMore } = this;
        const { loading, error, data, showShowMoreButton } = this.state;
        const { classes } = this.props;
        let numClassCardsShown = 0;
        let cardsToShow = data;

        if (showShowMoreButton) {
            cardsToShow = data.filter(() => {
                numClassCardsShown++;
                return numClassCardsShown <= 2;
            });
        }

        return (
            <div>
                {loading &&
                <div>
                    <CircularProgress />
                </div>}
                {!loading && error &&
                <div>
                    <Typography variant="body2" gutterBottom>{error}</Typography>
                </div>}
                {!loading && data.length > 0 &&
                <Grid container spacing={1}>
                    {cardsToShow.map((ds, i) => {
                        return (<Grid item xs={12} key={`classcard-${i}`}>
                            <ClassCard data={ds} />
                        </Grid>);
                    })}
                </Grid>}
                {showShowMoreButton && data.length > 2 &&
                <div className={classes.showMoreButton}>
                    <Button
                        variant="outlined"
                        onClick={handleShowMore}
                    >
                        Show more
                    </Button>
                </div>}
                {!loading && data.length === 0 && !error &&
                <div>
                    <Typography variant="body2" gutterBottom>No upcoming classes</Typography>
                </div>}
            </div>
        );
    }
}

UpcomingClasses.propTypes = {
    instructorId: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
};

const useStyles = () => ({
    showMoreButton: {
        marginTop: 20,
        textAlign: "center"
    }
});

export default withStyles(useStyles)(UpcomingClasses);
