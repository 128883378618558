import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';

import { logInstructorSelected } from "../../utils/firebaseAnalytics";

export default function InstructorCard({ data }) {
    const classes = useStyles();

    return (<Card elevation={1}>
        <CardActionArea
            to={`/${data.handle}`}
            onClick={() => logInstructorSelected({
                instructorId: data.id,
                source: "instructorPage"
            })}
            component={Link}
            className={classes.cardAction}
        >
            {data.media.coverUrl &&
            <div className={classes.titleWrapper}>
                <CardMedia
                    className={classes.media}
                    component="img"
                    image={data.media.coverUrl}
                    title={`${data.firstname} ${data.lastname}`}
                />
                <div className={classes.mediaOverlay}>
                    <div className={classes.instructorBrief}>
                        <h2 className={classes.instructorHeader}>{data.firstname} {data.lastname}</h2>
                        <div>{data.title}</div>
                    </div>
                </div>
            </div>}
            {!data.media.coverUrl &&
            <div className={classes.titleWrapperNoCover}>
                <div className={classes.instructorBrief}>
                    <Avatar
                        alt={`${data.firstname} ${data.lastname}`}
                        className={classes.avatar}
                        src={data.media.profileUrl}
                    />
                    <h2 className={classes.instructorHeader}>{data.firstname} {data.lastname}</h2>
                    <div>{data.title}</div>
                </div>
            </div>}
        </CardActionArea>
    </Card>);
}

InstructorCard.propTypes = {
    data: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        marginRight: theme.spacing(2),
        alignSelf: "center",
        border: "1px solid white",
    },
    media: {
        height: 400,
    },
    cardAction: {
        display: "flex"
    },
    mediaOverlay: {
        position: "absolute",
        background: "linear-gradient(0deg, rgba(0,0,0,0.7343312324929971) 10%, rgba(255,255,255,0) 85%)",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "block"
    },
    titleWrapper: {
        display: "inline-block",
        position: "relative",
        width: "100%"
    },
    titleWrapperNoCover: {
        display: "inline-block",
        position: "relative",
        backgroundColor: "#000000",
        width: "100%",
        height: 400
    },
    instructorBrief: {
        height: "100%",
        width: "100%",
        textAlign: "center",
        bottom: 0,
        color: "white",
        justifyContent: "flex-end",
        display: "flex",
        flexDirection: "column",
        paddingBottom: theme.spacing(6),
        fontWeight: "normal"
    },
    instructorHeader: {
        fontWeight: "bold"
    },
}));