import React from "react";
import {Helmet} from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import { title, primaryColor, container } from "assets/jss/dancetonight.js";

export default function JoinPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const title = 'Join Dance Tonight as an Instructor';
    const description = "Expand your audience. Find new students. Dance Tonight is open to a limited number of instructors and classes in the Bay Area.";

    return (<div>
        <Helmet>
            <title>{title}</title>
            <meta name="og:title" property="og:title" content={title} />
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            {/* <meta property="og:url" content="https://dancetonightbayarea.com/download" /> */}
            <meta name="twitter:card" content="summary" />
        </Helmet>
        <Header
            color="black"
            rightLinks={<HeaderLinks />}
            brand="Dance Tonight"
            absolute
            {...rest}
        />
        <div className={classes.main}>
            <div className={classes.container}>
                <Hidden mdUp>
                    <Typography variant="h2" component="h5" className={classes.header} gutterBottom>
                        Made for instructors
                    </Typography>
                </Hidden>
                <GridContainer alignItems="center" justify="center">
                    <GridItem xs={12} sm={12} md={6} className={classes.imgWrapper}>
                        <img
                            src={require("assets/img/instructor-iphone.jpg")}
                            alt=""
                            className={classes.img}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div>
                            <Hidden smDown>
                                <h2 className={classes.title}>Made for instructors</h2>
                            </Hidden>
                            <h5 className={classes.description}>
                                Expand your audience. Find new students.
                                Dance Tonight is open to a limited number of instructors and classes in the Bay Area.
                                Contact us to find out how to get your classes in our app.
                            </h5>
                        </div>
                        <div className={classes.contactWrapper}>
                            <h3 className={classes.title2}>Instructors</h3>
                            <h5 className={classes.description}>
                                <a href="mailto:join@dancetonightbayarea.com" alt="" className={classes.link}>
                                    join@dancetonightbayarea.com
                                </a>
                            </h5>
                            <h3 className={classes.title2}>Other inquiries</h3>
                            <h5 className={classes.description}>
                                <a href="mailto:contact@dancetonightbayarea.com" alt="" className={classes.link}>
                                    contact@dancetonightbayarea.com
                                </a>
                            </h5>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
        <Footer />
    </div>);
}

const landingPageStyle = (theme) => ({
    container: {
        paddingTop: "100px",
        paddingBottom: "60px",
        zIndex: "12",
        color: "#FFFFFF",
        ...container
    },
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3",
        padding: "70px 15px",
        minHeight: "100vh"
    },
    header: {
        ...title,
        marginTop: "0",
        textAlign: "center"
    },
    title: {
        ...title,
        marginBottom: "1rem",
        minHeight: "32px",
        textDecoration: "none",
        // color: "white"
    },
    title2: {
        ...title,
        marginBottom: theme.spacing(0),
        marginTop: 0,
        minHeight: "32px",
        textDecoration: "none",
        color: "white"
    },
    description: {
        color: "#777"
    },
    img: {
        maxWidth: "100%",
        maxHeight: "700px",
        height: "auto",
    },
    imgWrapper: {
        textAlign: "center"
    },
    contactWrapper: {
        marginTop: theme.spacing(6),
        borderRadius: 8,
        backgroundColor: "black",
        padding: theme.spacing(2),
    },
    link: {
        color: primaryColor,
        textDecoration: "none",
        backgroundColor: "transparent",
        "&:hover,&:focus": {
            color: "purple",
            textDecoration: "none"
        }
    },
});
const useStyles = makeStyles(landingPageStyle);