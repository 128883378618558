import React from "react";
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";

import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import ClassesListRow from "./ClassesListRow";
import { listUpcomingEvents, formatClassInstance } from "utils/firebaseStore";

class ClassesListPage extends React.Component {
    state = {
        error: null,
        loading: true,
        data: [],
        paginationSnapshot: null
    };

    componentDidMount = () => {
        this.fetchUpcomingEvents();
    }

    fetchUpcomingEvents = (paginationSnapshot) => {
        listUpcomingEvents(paginationSnapshot).then((querySnapshot) => {
            const data = []
            let lastSnapshot = null;
            querySnapshot.forEach((doc) => {
                lastSnapshot = doc;
                data.push(formatClassInstance(doc.id, doc.data()));
            });

            this.setState(prevState => ({
                error: null,
                loading: false,
                data: [...prevState.data, ...data],
                paginationSnapshot: lastSnapshot
            }))
        }).catch((error) => {
            console.log("Error getting events:", error);
            this.setState({
                error: "Something didn't go right. Please try refreshing.",
                loading: false,
            });
        });
    }

    handleLoadMore = () => {
        const { paginationSnapshot } = this.state;
        this.fetchUpcomingEvents(paginationSnapshot);
        this.setState({
            error: null,
            loading: true,
        });
    }

    renderHeaderMeta = () => {
        const title = 'Dance classes in the Bay Area - Dance Tonight';
        const description = 'Discover dance classes in the San Francisco Bay Area. Find multiple dance styles such as Bachata, Salsa, Kizomba, and more.';

        return (<Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            {/* <meta property="og:url" content={`https://dancetonightbayarea.com/c/${data.id}`} /> */}
        </Helmet>)
    }

    render() {
        const { renderHeaderMeta, handleLoadMore } = this;
        const { classes } = this.props;
        const { loading, error, data, paginationSnapshot } = this.state;

        return (<div className={classes.root}>
            {renderHeaderMeta()}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2" component="h1" gutterBottom className={classes.header}>
                        Classes in the Bay Area
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        {data.map(function(d) {
                            return (<Grid item xs={12} key={`classevent-${d.class_id} ${d.id}`}>
                                <ClassesListRow data={d} />
                            </Grid>);
                        })}
                    </Grid>
                </Grid>
            </Grid>
            {loading &&
            <div className={classes.loaderBody}>
                <CircularProgress />
            </div>}
            {!loading && error &&
            <div className={classes.errorContent}>
                <Typography variant="h2" component={"div"} gutterBottom>
                    Oops
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {error}
                </Typography>
            </div>}
            {!loading && paginationSnapshot && <div className={classes.loadMore}>
                <Button variant="outlined" onClick={handleLoadMore}>
                    Load more
                </Button>
            </div>}
        </div>);
    }
}

ClassesListPage.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

const useStyles = (theme) => ({
    root: {
        margin: "0 auto 0 auto",
        maxWidth: theme.breakpoints.values.md, // 960px
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    loaderBody: {
        textAlign: "center",
        paddingTop: "150px",
        paddingBottom: "150px",
    },
    errorContent: {
        maxWidth: 600,
        paddingTop: "50px",
        paddingBottom: "250px",
    },
    header: {
        paddingTop: theme.spacing(3),
        textAlign: "center"
    },
    loadMore: {
        padding: theme.spacing(4),
        width: "100%",
        textAlign: "center"
    }
});

export default withStyles(useStyles)(ClassesListPage);