export const capitalizeArrayOfText = (arrayOfText) => {
    return arrayOfText.map((text) => text.charAt(0).toUpperCase() + text.substring(1));
}

export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getUrlFriendlyEventTitle = (title) => {
    let titleUrl = title.replace(/[^a-zA-Z0-9 ]/g, '');
    titleUrl = titleUrl.replace(/[\s]+/g, '-');
    return encodeURIComponent(`${titleUrl}`).toLowerCase();
}

export const getPriceSummaryFromPriceOptions = (priceOptions) => {
    let costCaption = 'Free';
    if (priceOptions.length === 1) {
        const costValues = priceOptions.map(option => option.value);
        costCaption = (costValues[0] === 0) ? 'Free' : `$${costValues[0]}`;
    } else if (priceOptions.length > 1) {
        const costValues = priceOptions.map(option => option.value);
        const loCost = Math.min(...costValues);
        const hiCost = Math.max(...costValues);
        costCaption = (loCost === hiCost) ? `$${loCost}` : `$${loCost}+`;
    }

    return costCaption;
}