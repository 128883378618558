import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import isThisWeek from 'date-fns/isThisWeek';
import format from 'date-fns/format';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInHours from 'date-fns/differenceInHours';
import isAfter from 'date-fns/isAfter';
import isWithinInterval from 'date-fns/isWithinInterval';

export const formatEventStartTime = (date) => {
    const m = date.getMinutes();
    const hourFormat = (m === 0) ? 'ha' : 'h:ma';

    if (isToday(date)) {
        return format(date, `'Today', MMM d 'at' ${hourFormat}`);
    } else if (isTomorrow(date)) {
        return format(date, `'Tomorrow', MMM d 'at' ${hourFormat}`);
    } else if (isThisWeek(date)) {
        return format(date, `'This' EEEE, MMM d 'at' ${hourFormat}`);
    } else{
        return format(date, `EEEE, MMM d, yyyy 'at' ${hourFormat}`);
    }
}

export const formatEventDuration = (startsAt, endsAt) => {
    const minutes = differenceInMinutes(endsAt, startsAt);
    const hours = differenceInHours(endsAt, startsAt);

    if (minutes < 60) {
        return minutes === 1 ? `${minutes} min`: `${minutes} mins`;
    } else if (hours < 12) {
        const remaining = minutes % 60;
        if (remaining > 0) {
            return hours === 1 ? `${hours} hr ${remaining}m` : `${hours} hrs ${remaining}m`;
        }
        
        return hours === 1 ? `${hours} hr` : `${hours} hrs`;
    }

    return 'All day';
}

export const isEventHappeningNow = (startsAt, endsAt) => {
    const now = new Date();
    return isWithinInterval(now, {
        start: startsAt,
        end: endsAt
    })
}

export const isEventOver = (endsAt) => {
    const now = new Date();
    return isAfter(now, endsAt);
}

export const getNextOrLastOccurringDateIdx = (schedule, fromDate) => {
    let nextOrLastOccurrenceIdx;
    for (let i = 0; i < schedule.length; i++) {
        nextOrLastOccurrenceIdx = i;
        if (isAfter(schedule[i].starts_at, fromDate)) {
            break;
        }
    }

    return nextOrLastOccurrenceIdx;
}