import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "assets/jss/dancetonight.js";
import { logInstructorSelected } from "utils/firebaseAnalytics";

export default function ClassInstructors({ instructors }) {
    const classes = useStyles();
    return (<List>
        {instructors.map((instructor, i) => {
            return (<ListItem key={`instructor-${i}`}>
                <ListItemAvatar>
                    <Avatar
                        alt={`${instructor.firstname} ${instructor.lastname}`}
                        src={instructor.profileUrl}
                    />
                </ListItemAvatar>
                <ListItemText>
                    <Link
                        to={`/p/${instructor.firstname}-${instructor.lastname}-${instructor.id}`}
                        className={classes.link}
                        onClick={() => logInstructorSelected({
                            instructorId: instructor.id,
                            source: "eventPage"
                        })}
                    >
                        {instructor.firstname} {instructor.lastname}
                    </Link>
                </ListItemText>
            </ListItem>)
        })}
    </List>);
}

ClassInstructors.propTypes = {
    instructors: PropTypes.array.isRequired,
};

const useStyles = makeStyles({
    link: {
        color: primaryColor,
        textDecoration: "none",
        backgroundColor: "transparent",
        "&:hover,&:focus": {
            color: "purple",
            textDecoration: "none"
        }
    }
})