import React from "react";
import {Helmet} from "react-helmet";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import { container, title, primaryColor } from "assets/jss/dancetonight.js";

export default function PrivacyPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    return (<div>
        <Helmet>
            <title>Dance Tonight Privacy Policy</title>
            <meta name="og:title" property="og:title" content="Dance Tonight Privacy Policy" />
            {/* <meta property="og:url" content="https://dancetonightbayarea.com/privacy" /> */}
            <meta name="twitter:card" content="summary" />
        </Helmet>
        <Header
            color="black"
            rightLinks={<HeaderLinks />}
            brand="Dance Tonight"
            absolute
            {...rest}
        />
        <div className={classNames(classes.main)}>
            <div className={classes.container}>
                <h1 className={classes.title}>Privacy policy</h1>
                <Typography variant="body2" color="textSecondary">Effective July 28, 2020</Typography>
                <h3 className={classes.title}>Information we collect</h3>
                <h4 className={classes.title}>Information given to us</h4>
                <Typography variant="body2" color="textSecondary">
                    Dance Tonight is a free service and does not require registration or any personal data from you to function.
                    We do not ask for any personal information from you upon installing the app or while browsing any of our pages.
                </Typography>
                <h4 className={classes.title}>Information automatically collected</h4>
                <Typography variant="body2" color="textSecondary">
                    We do not require registration or for you to fill out any forms to use our service,
                    so in order to identify you as a returning user, we generate an anonymous UID for you.
                    This UID is a unique string of alphanumeric characters and does not personally identify you.
                </Typography>
                <h3 className={classes.title}>How do we collect information</h3>
                <h4 className={classes.title}>Information given to us</h4>
                <Typography variant="body2" color="textSecondary">
                    We do not collect personal information from you, period.
                </Typography>
                <h4 className={classes.title}>Information automatically collected</h4>
                <Typography variant="body2" color="textSecondary">
                    Upon opening the app, we check if it’s your first time opening the app, and if so, we generate an anonymous UID.
                    We store this UID in our database and the device where the app is installed.
                    This lets us know you are a returning user.
                </Typography>
                <h3 className={classes.title}>Use of third party services</h3>
                <Typography variant="body2" color="textSecondary">
                    While we do not ask nor collect personal data from you,
                    we use the following third-party services to make informed decisions to improve the Dance Tonight service.    
                </Typography>
                <h4 className={classes.title}>Google Analytics</h4>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    We use Google Analytics (GA) to analyze website traffic.
                    This helps us understand usage patterns, and use that information to improve the Dance Tonight experience.
                    We do not send personally identifiable information to Google.
                    The data that we send to GA is dance classes selected along with difficulties and styles and dance class IDs.
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    GA uses cookies to track information about a user visiting our website or app.
                    We send events to Google when a page is visited, when a button is clicked, or when a screen is loaded in the app.
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Follow this link to learn more about how Google collects and processes data{" "}
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://policies.google.com/technologies/partner-sites"
                        className={classes.link}
                    >
                        https://policies.google.com/technologies/partner-sites
                    </Link>
                </Typography>
                <h4 className={classes.title}>Sentry.io</h4>
                <Typography variant="body2" color="textSecondary">
                    Sentry is an open-source error tracking system which allows us to monitor crashes and errors in our services.
                    We use sentry to collect the following information: device type, deviceId, operating system, app version, and code stack traces.
                    We do not send personally identifiable information to Sentry.
                    In the event that an error or crash occurs while you are browsing our services, we send information about the error to Sentry.
                </Typography>
                <h3 className={classes.title}>Personal Data of Children</h3>
                <Typography variant="body2" color="textSecondary">
                    Our service is not intended for children under 16 years of age.
                    We do not collect any personal information from anyone regardless of age. 
                </Typography>
                <h3 className={classes.title}>Links to other websites</h3>
                <Typography variant="body2" color="textSecondary">
                    In order to provide you with all the information you need to find a dance class, we often supplement our content with links to pages outside of Dance Tonight. We do our best to link to websites that we deem trustworthy, but we assume no responsibility for the content, privacy policies or practices of any third party sites or services. We encourage you to review the privacy policy of the websites you visit.
                </Typography>
                <h3 className={classes.title}>Changes to privacy policy</h3>
                <Typography variant="body2" color="textSecondary">
                    We may update our privacy policy from time to time.
                    If you are a user of the app, or we have your contact information,
                    we will notify you of any changes by posting the new privacy policy on this page.
                </Typography>
                <h3 className={classes.title}>Contact Us</h3>
                <Typography variant="body2" color="textSecondary">
                    If you have any questions about this privacy policy, you can contact us by email via{" "}
                    <Link
                        target="_blank"
                        href="mailto:privacy@dancetonightbayarea.com"
                        className={classes.link}
                    >
                        privacy@dancetonightbayarea.com
                    </Link>
                </Typography>
            </div>
        </div>
        <Footer />
    </div>);
}

const landingPageStyle = {
    container: {
        paddingTop: "100px",
        paddingBottom: "60px",
        zIndex: "12",
        color: "#FFFFFF",
        ...container
    },
    title: {
        ...title,
    },
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3"
    },
    link: {
        color: primaryColor,
        textDecoration: "none",
        backgroundColor: "transparent",
        "&:hover,&:focus": {
            color: "purple",
            textDecoration: "none"
        }
    },
};
const useStyles = makeStyles(landingPageStyle);