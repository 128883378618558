import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { title } from "assets/jss/dancetonight.js";

import downloadSvg from "assets/img/downloadappstore_black.svg";

const productStyle = (theme) => ({
  section: {
    padding: "220px 15px 220px",
    textAlign: "center",
    backgroundColor: "black",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    color: "white"
  },
  description: {
    color: "#ccc"
  },
  img: {
    height: "60px"
  },
  chip: {
    padding: theme.spacing(2),
    backgroundColor: "black",
    color: "white",
    fontSize: "1em",
    fontWeight: "400",
    "&:hover,&:focus": {
      backgroundColor: "black",
      color: "white",
      textDecoration: "none"
    }
  },
});

const useStyles = makeStyles(productStyle);

export default function DownloadSection() {
  const classes = useStyles();
  return (
    <div className={classes.section} id="download">
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Download now and start your journey</h2>
          <h5 className={classes.description}>
            All you need to find dance classes in one app. Available on iOS. Android coming soon.
          </h5>
          <br/><br/>
          <a
            href="https://apps.apple.com/us/app/id1512534760"
            target="_blank" 
            rel="noopener noreferrer"
            alt="Download from App Store"
          >
            <img src={downloadSvg} alt="Download" className={classes.img} />
          </a>
        </GridItem>
      </GridContainer>
    </div>
  );
}
